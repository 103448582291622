<template>
  <div class="main-sidebar">
    <div class="main-sidebar__nav pt-3">
      <div
        class="list-group list-group-flush"
        style="height: calc(100vh - 50px); overflow: auto"
      >
        <router-link
          v-for="route in filteredMenu"
          :key="route.path"
          :to="route.path"
        >
          <img :src="require(`../../assets/images/menu/${constantes.EMPRESA}/${route.icon}.svg`)" />{{
            route.label
          }}
        </router-link>
      </div>
    </div>
    
  </div>
</template>

<script>
import { checkUserHaveRole } from "../../tools";
import { constantes } from "@/constants";

export default {
  data() {
    return {
      constantes: constantes,
      routes: [
        {
          label: "Home",
          icon: "home",
          path: "/",
          roles: ["ROLE_ADMIN"],
        },
        {
          label: "Clientes",
          icon: "instituicao",
          path: "clientes",
          roles: ["ROLE_SUPER_ADMIN"],
        },
        {
          label: "Instituições",
          icon: "instituicao",
          path: "instituicoes",
          roles: ["ROLE_CLIENTE_ADMIN"],
        },
        {
          label: "Professores",
          icon: "professores",
          path: "professores",
          roles: [
            "ROLE_INSTITUICAO_ADMIN",
          ],
        },
        // {
        //   label: "Lixeira de Professores",
        //   icon: "home",
        //   path: "professoresTrash",
        //   roles: [
        //     "ROLE_INSTITUICAO_ADMIN",
        //     "ROLE_SUPER_ADMIN",
        //     "ROLE_CLIENTE_ADMIN",
        //   ],
        // },
        {
          label: "Anos",
          icon: "home",
          path: "anos",
          roles: ["ROLE_SUPER_ADMIN", "ROLE_INSTITUICAO_ADMIN"],
        },
        {
          label: "Turmas",
          icon: "home",
          path: "turmas",
          roles: ["ROLE_INSTITUICAO_ADMIN"],
        },
        {
          label: "Alunos",
          icon: "home",
          path: "alunos",
          roles: ["ROLE_INSTITUICAO_ADMIN"],
        },
        {
          label: "Banco de Questões",
          icon: "database",
          path: "/banco-de-questoes",
          roles: [
            "ROLE_SUPER_ADMIN",
            "ROLE_INSTITUICAO_ADMIN",
          ],
        },
        {
          label: "Minhas Questões",
          icon: "database",
          path: "/banco-de-questoes",
          roles: [
            "ROLE_PROFESSOR",
          ],
        },
        // {
        //   label: "Lixeira de Questões",
        //   icon: "database",
        //   path: "/banco-de-questoes-trash",
        //   roles: [
        //     "ROLE_SUPER_ADMIN",
        //     "ROLE_INSTITUICAO_ADMIN",
        //     "ROLE_PROFESSOR",
        //   ],
        // },
        {
          label: "Provas",
          icon: "test",
          path: "/provas",
          roles: ["ROLE_INSTITUICAO_ADMIN", "ROLE_PROFESSOR"],
        },
        {
          label: "Agenda",
          icon: "calendar",
          path: "agenda",
          roles: ["ROLE_INSTITUICAO_ADMIN", "ROLE_PROFESSOR"],
        },
        {
          label: "Componentes Curric.",
          icon: "disciplina",
          path: "componente-curricular",
          roles: ["ROLE_SUPER_ADMIN", "ROLE_INSTITUICAO_ADMIN"],
        },
        {
          label: "Habilidades",
          icon: "assunto",
          path: "habilidades",
          roles: ["ROLE_SUPER_ADMIN", "ROLE_INSTITUICAO_ADMIN", "ROLE_PROFESSOR"],
        },
        {
          label: "Resultados",
          icon: "chart-bars",
          path: "/resultados",
          roles: [
            "ROLE_SUPER_ADMIN",
            "ROLE_INSTITUICAO_ADMIN",
            "ROLE_PROFESSOR",
          ],
        },
        {
          label: "FAQ",
          icon: "question",
          path: "faq",
          roles: ["ROLE_SUPER_ADMIN", "ROLE_PROFESSOR"],
        },
        {
          label: "Contato",
          icon: "bubble",
          path: "contato",
          roles: ["ROLE_SUPER_ADMIN", "ROLE_INSTITUICAO_ADMIN",],
        },
        // {
        //   label:'Usuários',
        //   icon:'bubble',
        //   path:'usuarios'
        // }
      ],
    };
  },
  computed: {
    filteredMenu() {
      return this.routes.filter((row) => {
        if (checkUserHaveRole(row.roles)) {
          return row;
        }
      });
    },
  },
  created() {},
};
</script>

<style lang="scss">
.version{
  color: white;
  margin-left: 10px;
  font-style: italic;
  position: absolute;
  bottom: 0;
}
</style>
